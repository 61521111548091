var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VCard',{attrs:{"loading":_vm.loading,"disabled":_vm.loading}},[_c('VCardTitle',[_vm._v(" "+_vm._s(_vm.normalizedTitle)+" ")]),_c('VCardText',[_c('VForm',[_c('VContainer',{attrs:{"fluid":""}},[_c('VRow',{attrs:{"align":"center"}},[_c('VCol',[_c('VTextField',{attrs:{"value":_vm.entity.title,"disabled":_vm.isReadOnly('title'),"label":"Заголовок","name":"questionTitle","persistent-hint":"","hint":"заголовок вопроса"},on:{"input":function($event){return _vm.$emit('update:title', $event)}}})],1)],1),_c('VRow',{attrs:{"align":"center"}},[_c('VCol',[_c('VTextarea',{attrs:{"value":_vm.entity.description,"label":"Описание","name":"questionDescription","persistent-hint":"","hint":"описание вопроса"},on:{"input":function($event){return _vm.$emit('update:description', $event)}}})],1)],1),_c('VRow',{attrs:{"align":"center"}},[_c('VCol',[_c('VCheckbox',{attrs:{"input-value":_vm.entity.active,"true-value":true,"false-value":false,"label":"Активен","name":"questionActive","persistent-hint":"","hint":"активность вопроса"},on:{"change":function($event){return _vm.$emit('update:active', $event)}}})],1)],1),_c('VRow',{attrs:{"align":"center"}},[_c('VCol',[_c('VAutocomplete',{attrs:{"value":_vm.entity.levelId,"items":_vm.levels,"filter":_vm.customFilter,"disabled":_vm.isReadOnly('levelId'),"item-text":"name","item-value":"id","label":"Уровень","name":"questionLevelId","persistent-hint":"","hint":"уровень вопроса"},on:{"input":function($event){return _vm.$emit('update:levelId', $event)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('VListItemContent',[_c('VListItemTitle',{domProps:{"textContent":_vm._s(item.name)}}),_c('VListItemSubtitle',{domProps:{"textContent":_vm._s(item.id)}})],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('VListItemContent',[_c('VListItemTitle',{domProps:{"textContent":_vm._s(item.name)}}),_c('VListItemSubtitle',{domProps:{"textContent":_vm._s(item.id)}})],1)]}}])})],1)],1),_c('VRow',{attrs:{"align":"center"}},[_c('VCol',[_c('VAutocomplete',{attrs:{"value":_vm.entity.questionType,"items":_vm.questionTypes,"filter":_vm.customFilter,"disabled":_vm.isReadOnly('questionType'),"item-text":"name","item-value":"id","label":"Тип","name":"questionQuestionType","persistent-hint":"","hint":"тип вопроса"},on:{"input":function($event){return _vm.$emit('update:questionType', $event)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('VListItemContent',[_c('VListItemTitle',{domProps:{"textContent":_vm._s(item.name)}}),_c('VListItemSubtitle',{domProps:{"textContent":_vm._s(item.id)}})],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('VListItemContent',[_c('VListItemTitle',{domProps:{"textContent":_vm._s(item.name)}}),_c('VListItemSubtitle',{domProps:{"textContent":_vm._s(item.id)}})],1)]}}])})],1)],1)],1)],1)],1),_c('VCardActions',[_c('VSpacer'),_c('VBtn',{attrs:{"text":""},on:{"click":_vm.handleCancel}},[_vm._v(" Отмена ")]),_c('VBtn',{attrs:{"color":"primary","text":""},on:{"click":_vm.handleSubmit}},[_vm._v(" "+_vm._s(_vm.normalizedSubmit)+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }