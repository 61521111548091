<template>
  <VCard
    :loading="loading"
    :disabled="loading"
  >
    <VCardTitle>
      {{ normalizedTitle }}
    </VCardTitle>

    <VCardText>
      <VForm>
        <VContainer fluid>
          <VRow align="center">
            <VCol>
              <VTextField
                :value="entity.title"
                :disabled="isReadOnly('title')"
                label="Заголовок"
                name="questionTitle"
                persistent-hint
                hint="заголовок вопроса"
                @input="$emit('update:title', $event)"
              />
            </VCol>
          </VRow>

          <VRow align="center">
            <VCol>
              <VTextarea
                :value="entity.description"
                label="Описание"
                name="questionDescription"
                persistent-hint
                hint="описание вопроса"
                @input="$emit('update:description', $event)"
              />
            </VCol>
          </VRow>

          <VRow align="center">
            <VCol>
              <VCheckbox
                :input-value="entity.active"
                :true-value="true"
                :false-value="false"
                label="Активен"
                name="questionActive"
                persistent-hint
                hint="активность вопроса"
                @change="$emit('update:active', $event)"
              />
            </VCol>
          </VRow>

          <VRow align="center">
            <VCol>
              <VAutocomplete
                :value="entity.levelId"
                :items="levels"
                :filter="customFilter"
                :disabled="isReadOnly('levelId')"
                item-text="name"
                item-value="id"
                label="Уровень"
                name="questionLevelId"
                persistent-hint
                hint="уровень вопроса"
                @input="$emit('update:levelId', $event)"
              >
                <template #selection="{ item }">
                  <VListItemContent>
                    <VListItemTitle v-text="item.name" />
                    <VListItemSubtitle v-text="item.id" />
                  </VListItemContent>
                </template>

                <template #item="{ item }">
                  <VListItemContent>
                    <VListItemTitle v-text="item.name" />
                    <VListItemSubtitle v-text="item.id" />
                  </VListItemContent>
                </template>
              </VAutocomplete>
            </VCol>
          </VRow>

          <VRow align="center">
            <VCol>
              <VAutocomplete
                :value="entity.questionType"
                :items="questionTypes"
                :filter="customFilter"
                :disabled="isReadOnly('questionType')"
                item-text="name"
                item-value="id"
                label="Тип"
                name="questionQuestionType"
                persistent-hint
                hint="тип вопроса"
                @input="$emit('update:questionType', $event)"
              >
                <template #selection="{ item }">
                  <VListItemContent>
                    <VListItemTitle v-text="item.name" />
                    <VListItemSubtitle v-text="item.id" />
                  </VListItemContent>
                </template>

                <template #item="{ item }">
                  <VListItemContent>
                    <VListItemTitle v-text="item.name" />
                    <VListItemSubtitle v-text="item.id" />
                  </VListItemContent>
                </template>
              </VAutocomplete>
            </VCol>
          </VRow>
        </VContainer>
      </VForm>
    </VCardText>

    <VCardActions>
      <VSpacer />

      <VBtn
        text
        @click="handleCancel"
      >
        Отмена
      </VBtn>

      <VBtn
        color="primary"
        text
        @click="handleSubmit"
      >
        {{ normalizedSubmit }}
      </VBtn>
    </VCardActions>
  </VCard>
</template>

<script>
const QuestionTypes = {
  QUESTION_TYPE_INVALID: 'QUESTION_TYPE_INVALID',
  QUESTION_TYPE_MONO: 'QUESTION_TYPE_MONO',
  QUESTION_TYPE_POLY: 'QUESTION_TYPE_POLY',
};

export default {
  name: 'QuestionForm',

  props: {
    entity: {
      type: Object,
      default: () => ({}),
    },

    levels: {
      type: Array,
      required: true,
    },

    questionTypes: {
      type: Array,
      default: () => [
        { id: QuestionTypes.QUESTION_TYPE_MONO, name: 'Mono' },
        { id: QuestionTypes.QUESTION_TYPE_POLY, name: 'Poly' },
        { id: QuestionTypes.QUESTION_TYPE_INVALID, name: 'Invalid' },
      ],
    },

    loading: {
      type: Boolean,
      default: false,
    },

    readOnly: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    normalizedTitle() {
      let title = 'Создание вопроса';

      if (this.entity.id !== null && this.entity.id !== undefined) {
        title = 'Редактирование вопроса';
      }

      return title;
    },

    normalizedSubmit() {
      let title = 'Добавить';

      if (this.entity.id !== null) {
        title = 'Сохранить';
      }

      return title;
    },
  },

  methods: {
    customFilter(item, queryText) {
      const name = item.name.toLowerCase();
      const uuid = item.id.toLowerCase();
      const searchText = queryText.toLowerCase();

      return name.indexOf(searchText) > -1 || uuid.indexOf(searchText) > -1;
    },

    handleCancel() {
      this.$emit('cancel');
    },

    handleSubmit() {
      this.$emit('submit', this.entity);
    },

    isReadOnly(key) {
      return this.readOnly.includes(key);
    },
  },
};
</script>
