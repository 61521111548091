<template>
  <TTView>
    <VRow>
      <VCol>
        <QuestionForm
          :entity="question"
          :levels="levels"
          :loading="loading"
          :read-only="['levelId']"
          @update:title="question.title = $event"
          @update:description="question.description = $event"
          @update:levelId="question.levelId = $event"
          @update:questionType="question.questionType = $event"
          @update:active="question.active = $event"
          @cancel="handleCancel"
          @submit="handleSubmit"
        />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import QuestionForm from '../../../components/multi-level-test/questions/QuestionForm.vue';

export default {
  name: 'LevelsQuestionsCreate',

  components: {
    QuestionForm,
  },

  data() {
    return {
      loading: false,
      levels: [],
      question: {
        title: null,
        description: null,
        levelId: null,
        questionType: null,
        active: true,
      },
    };
  },
  computed: {
    testId() {
      return this.$route.params.testId;
    },

    levelId() {
      return this.$route.params.levelId;
    },
  },

  async created() {
    await this.fetch();

    this.question.levelId = this.levelId;
  },

  methods: {
    async fetch() {
      try {
        this.loading = true;

        const { levels } = await this.$di.api.MultiLevelTest.LevelsIndex();

        this.levels = levels;
      } catch (err) {
        // no-error
      } finally {
        this.loading = false;
      }
    },

    async handleSubmit() {
      try {
        this.loading = true;

        const { question } = this;
        const data = { question };

        await this.$di.api.MultiLevelTest.QuestionsCreate({ data });

        this.$router.go(-1);
        // TODO: показать уведомление
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },

    handleCancel() {
      this.$router.go(-1);
    },
  },
};
</script>
